import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { UserContext } from '../common/user';
import { VirtualList, useNotification } from '@bbnpm/bb-ui-framework';
import partition from 'lodash/partition';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { getUserIbChats, getIbRoom } from '../common/api';
import { BorderlessListItem } from '../common/components/BorderlessListItem';
import { PanelHeader } from '../common/components/PanelHeader';
import { ROOT_PATH } from '../common/paths';
import { getRandomHolidayIcon, getBbgHolidayName } from '../common/util';
import { device } from '../common/mediaQuery';
import styled from 'styled-components';
import { Input, InputGroup, EmptyState } from '@bbnpm/bb-ui-framework';
import MagnifyIcon from '@bloomberg/web-icons-react/Magnify';

function IbChatList() {
  const user = useContext(UserContext);
  const { ibChatsResponse } = useRouteLoaderData('home-router');
  const initialLoadDone = useRef(false);
  const sortedIbChats = sortChats(ibChatsResponse);
  const [chatList, setChatList] = useState(sortedIbChats);
  const [filteredChatList, setFilteredChatList] = useState(sortedIbChats);
  const notification = useNotification();
  const navigate = useNavigate();
  const holiday = getBbgHolidayName();
  const [chatFilter, setChatFilter] = useState('');

  useEffect(() => {
    if (!initialLoadDone.current) {
      validateChatList(ibChatsResponse);
      initialLoadDone.current = true;
    }
  });

  const validateChatList = useCallback(
    (chats) => {
      if (chats.error) {
        notification.addError({
          message: (
            <span>
              Failed to find IB chats. <a href={ROOT_PATH}>Refresh</a>
            </span>
          ),
        });
        return false;
      }
      return true;
    },
    [notification]
  );

  const updateChatList = useCallback(async () => {
    const chats = await getUserIbChats(user.loginJwt, true);
    if (initialLoadDone.current && validateChatList(chats)) {
      setChatList(sortChats(chats));
    }
  }, [user, validateChatList]);

  useEffect(() => {
    setFilteredChatList(
      chatList.filter((chat) =>
        chat.chatRoomTitle.toUpperCase().includes(chatFilter.toUpperCase())
      )
    );
  }, [chatList, chatFilter]);

  useEffect(() => {
    window.addEventListener('focus', updateChatList);
    return () => window.removeEventListener('focus', updateChatList);
  }, [updateChatList]);

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: 'rgb(25,25,25)',
        overflow: 'hidden',
      }}
    >
      <StyledPanelHeader isInternalUser={user?.isInternalUser}>
        IB Chat Rooms
      </StyledPanelHeader>
      <InputGroup
        style={{
          width: 'calc(100% - 16px)',
          height: '46px',
          margin: '8px 0px 8px 8px',
        }}
      >
        <InputGroup.Addon style={{ width: '50px' }}>
          <MagnifyIcon />
        </InputGroup.Addon>
        <InputGroup.Addon style={{ marginLeft: '-2px', width: '100%' }}>
          <Input
            placeholder='Search Chats'
            onChange={(evt) => setChatFilter(evt.target.value)}
          />
        </InputGroup.Addon>
      </InputGroup>
      {filteredChatList.length === 0 ? (
        <EmptyState
          title='No chats found'
          variant='empty'
          size='medium'
          description={`No chats matched with "${chatFilter}". Please make sure all words are spelled correctly or try a different keyword.`}
        />
      ) : (
        <ListContainer isInternalUser={user?.isInternalUser}>
          <VirtualList style={{ overflowY: 'hidden !important' }}>
            {filteredChatList.map((ib, i) => (
              <StyledVirtualListItem
                key={i}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#373737',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0,
                      }}
                    >
                      {ib.chatRoomType === 'pchat' ? (
                        <img
                          src={
                            holiday
                              ? getRandomHolidayIcon(holiday)
                              : 'ic_people_group_fill_24.svg'
                          }
                          alt=''
                          width='22px'
                        />
                      ) : ib.chatRoomType === 'forum' ? (
                        <img
                          src='ib-forum-mask.svg'
                          alt=''
                          style={{
                            marginTop: '2px',
                          }}
                          width='22px'
                        />
                      ) : (
                        <div
                          style={{
                            color: '#ffa028',
                            fontSize: '12px',
                          }}
                        >
                          {getInitials(ib.chatRoomTitle)}
                        </div>
                      )}
                    </div>
                    {ib.associatedConferenceRoom &&
                    ib.associatedConferenceRoom.size ? (
                      <sub
                        style={{
                          alignSelf: 'flex-end',
                          marginLeft: '-8px',
                          marginRight: '12px',
                        }}
                      >
                        <img src='ic_videocam.svg' style={{}} alt='' />
                      </sub>
                    ) : (
                      <span style={{ marginRight: '20px' }}></span>
                    )}
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ib.chatRoomTitle}
                    </span>
                  </div>
                }
                onClick={() => joinChatRoom(ib, user.loginJwt)}
              />
            ))}
          </VirtualList>
        </ListContainer>
      )}
    </div>
  );

  async function joinChatRoom(chat, loginJwt) {
    if (chat.associatedConferenceRoom) {
      joinRoom(chat.associatedConferenceRoom.roomId);
    } else {
      const res = await getIbRoom(
        chat.chatRoomId,
        chat.chatRoomType,
        chat.chatRoomTitle,
        loginJwt
      );
      if (res.roomId) {
        joinRoom(res.roomId);
      } else {
        notification.addError({
          message: `Failed to get room for ${chat.chatRoomTitle}.`,
        });
      }
    }
  }

  function joinRoom(roomId) {
    navigate(`/rooms/${roomId}`);
  }
}

function sortChats(chats) {
  const tChatType = 'tchat';
  const pChatType = 'pchat';
  const forumType = 'forum';

  const [inProgressChats, notInProgressChats] = partition(
    chats,
    (chat) =>
      chat.associatedConferenceRoom && chat.associatedConferenceRoom.size
  );

  const inProgressTChats = filterChatsByType(inProgressChats, tChatType);
  const inProgressForums = filterChatsByType(inProgressChats, forumType);
  const inProgressPChats = filterChatsByType(inProgressChats, pChatType);
  const notInProgressTChats = filterChatsByType(notInProgressChats, tChatType);
  const notInProgressForums = filterChatsByType(notInProgressChats, forumType);
  const notInProgressPChats = filterChatsByType(notInProgressChats, pChatType);

  return [
    ...sortChatsByTitle(inProgressTChats),
    ...sortChatsByTitle(inProgressForums),
    ...sortChatsByTitle(inProgressPChats),
    ...sortChatsByTitle(notInProgressTChats),
    ...sortChatsByTitle(notInProgressForums),
    ...sortChatsByTitle(notInProgressPChats),
  ];

  function filterChatsByType(chats, type) {
    return chats.filter((chat) => chat.chatRoomType === type);
  }

  function sortChatsByTitle(chats) {
    return chats.sort((a, b) => a.chatRoomTitle.localeCompare(b.chatRoomTitle));
  }
}

// Use same logic as IB: splits the string by spaces and returns all first characters; Words starting with a symbol are skipped.
// Reference: https://bbgithub.dev.bloomberg.com/ib-apps/ib2/blob/master/ib-session/client/pipelines/room-info-pipeline.ts#L37
// Add '+' on the initials for any transient chat that has more than 2 people

function getInitials(chatTitle) {
  // Checks for | (pipe) is temporary, it will be deleted soon.
  let firstPersonName = '';
  if (chatTitle.includes('|')) {
    firstPersonName = chatTitle.split(' | ')[0];
  } else {
    firstPersonName = chatTitle.split(', ')[0];
  }

  let initials = firstPersonName
    .split(' ')
    .map((part) => part[0])
    .filter((character) => character >= 'A' && character <= 'Z')
    .join('');

  if (initials.length > 2) {
    initials = initials.charAt(0) + initials.charAt(initials.length - 1);
  }

  if (chatTitle.includes(',') || chatTitle.includes('|')) {
    initials += '+';
  }

  return initials;
}

const ListContainer = styled.div`
  height: calc(100% - 116px);
  overflow-x: hidden;
  @media ${device.tablet} {
    height: ${(props) =>
      props.isInternalUser ? 'calc(100% - 62px)' : 'calc(100% - 116px)'};
  }
`;

// This seeks to overwrite changes in BB UI VirtualListItem
// so if they ever update their code we need to update this
const StyledVirtualListItem = styled(BorderlessListItem)`
  margin-left: 8px;
  width: calc(100% - 16px) !important;
  cursor: pointer !important;

  > div {
    width: calc(100%) !important;
  }
`;

const StyledPanelHeader = styled(PanelHeader)`
  @media ${device.tablet} {
    display: ${(props) => (props.isInternalUser ? 'none' : 'block')};
  }
`;

export default IbChatList;
