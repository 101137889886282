import {
  ErrorResponse,
  Navigate,
  Params,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom';
import { EmptyState, useNotification } from '@bbnpm/bb-ui-framework';
import Help from './help';
import Home from './home';
import LandingPage from './landingPage';
import { InfoPage, PageContainer } from './infoPage/InfoPage';
import Room from './room';
import TestWebRTC from './testWebRTC';
import { GlobalLaunchState } from './common/launchState';
import {
  ERROR_PATH,
  HELP_PATH,
  HOME_PATH,
  LANDING_PATH,
  LOGOUT_PATH,
  MEETING_PATH,
  ROOT_PATH,
  TEST_WEBRTC_PATH,
} from './common/paths';
import { copyToClipboard, isFirstTimeUser, sanitizeUrl } from './common/util';
import {
  getShard,
  getRoomAuth,
  getIceServers,
  getPersonalRoom,
  getParticipantCount,
  getUserIbChats,
  login,
  getRoom,
} from './common/api';
import { getUserFromSessionCookie } from './common/user';
import { logger } from './common/logger';
import { validate as validateUuid } from 'uuid';
import { loadScript } from './common/loadScript';
import { getSelfInvitations } from './common/invitation';
import { getInvitationsSinceSecondsAgo } from './home/Home';
import { GetPersonalRoomResponse, Invitation } from './types';

enum ERROR_TYPE {
  ROOM_NOT_FOUND = 'ROOM_NOT_FOUND',
  BAD_JWT = 'BAD_JWT',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  NOT_AUTHORIZED_FOR_MEETING = 'NOT_AUTHORIZED_FOR_MEETING',
  GENERIC_SERVER_ERROR = 'GENERIC_SERVER_ERROR',
  UNSUPPORTED_BROWSER = 'UNSUPPORTED_BROWSER',
}

function LoginComponent() {
  return <>{login()}</>;
}

async function homeDataLoader() {
  const user = userDataLoader();
  const personalRoomP = getPersonalRoom(
    Number(user.uuid),
    user.loginJwt
  ) as Promise<GetPersonalRoomResponse>;
  const selfInvitationsP = getSelfInvitations(
    user,
    getInvitationsSinceSecondsAgo
  );

  const [
    ibChatsResponse,
    personalRoomResponse,
    selfInvitationsResponse,
    participantCountResponse,
    invitedRoomsResponse,
  ] = (await Promise.all([
    getUserIbChats(user.loginJwt, true),
    personalRoomP,
    selfInvitationsP,
    personalRoomP.then((personalRoomResp) =>
      getParticipantCount(personalRoomResp.roomId, user.loginJwt)
    ),
    selfInvitationsP.then((selfInvitationsResp) => {
      return getInvitedRoomsFromSelfInvitations(
        user.loginJwt,
        selfInvitationsResp
      );
    }),
    loadScript('/meetings.js?v=1.2.14'),
  ])) as any;

  return {
    ibChatsResponse,
    personalRoomResponse,
    participantCountResponse,
    selfInvitationsResponse,
    invitedRoomsResponse,
    ...user,
  };
}

async function getInvitedRoomsFromSelfInvitations(
  loginJwt: string,
  selfInvitations: Invitation[]
) {
  if (selfInvitations.length > 1) {
    return Promise.all(
      selfInvitations.map((selfInvitation: any) =>
        getRoom(selfInvitation.roomId, loginJwt)
      )
    );
  }

  return [];
}

async function userDataLandingFallbackLoader() {
  const user = getUserFromSessionCookie();
  if (!user) {
    await loadScript('/landing-page.js?v=2.1.1');

    // throw an error so that the error element is loaded
    throw new Error(ERROR_TYPE.NOT_AUTHENTICATED);
  }
  return user;
}

function userDataLoader() {
  const user = getUserFromSessionCookie();
  if (!user) {
    // throw an error so that the error element is loaded
    throw new Error(ERROR_TYPE.NOT_AUTHENTICATED);
  }
  return user;
}

async function joinRoomDataLoader({ params }: { params: Params<string> }) {
  const user = userDataLoader();
  const src = GlobalLaunchState.isLocal
    ? import.meta.env.VITE_JITSI_EXTERNAL_API_URL
    : `/libs/external_api.min.js?v=${import.meta.env.VITE_BUILD_VERSION}`;

  if (!params.roomId || !validateUuid(params.roomId)) {
    throw new Error(ERROR_TYPE.ROOM_NOT_FOUND);
  }
  const [roomAuthResponse, shardResponse, iceServersResponse] =
    (await Promise.all([
      getRoomAuth(params.roomId, user.loginJwt),
      getShard(params.roomId, user.loginJwt),
      getIceServers(user.loginJwt),
      loadScript(src),
    ])) as any;

  if (!shardResponse.shard || !shardResponse.region) {
    throw new Error(ERROR_TYPE.GENERIC_SERVER_ERROR);
  }

  if (
    shardResponse.error ||
    roomAuthResponse.error ||
    iceServersResponse.error
  ) {
    if (
      shardResponse.responseCode === 401 ||
      roomAuthResponse.responseCode === 401 ||
      iceServersResponse.responseCode === 401
    ) {
      // user has valid JWT, but not recognized by the endpoints
      throw new Error(ERROR_TYPE.BAD_JWT);
    } else if (roomAuthResponse.responseCode === 403) {
      // jitsi-management-service returns 403 if a user is logged in but not authorized for the meeting
      throw new Error(ERROR_TYPE.NOT_AUTHORIZED_FOR_MEETING);
    } else {
      throw new Error(ERROR_TYPE.GENERIC_SERVER_ERROR);
    }
  }

  return {
    shard: shardResponse.shard,
    region: shardResponse.region,
    roomAuth: roomAuthResponse,
    iceServers: iceServersResponse,
  };
}

function AuthenticationErrorPage() {
  return (
    <InfoPage
      variant='gated'
      title='Unable to Login'
      description='There was an error validating your identity. Please try again.'
      buttonText='Log In'
    />
  );
}

function LogoutPage() {
  return (
    <InfoPage
      title='Logout'
      description='You have successfully logged out of ROOM.'
      buttonText='Log In'
    />
  );
}

function RootErrorBoundary() {
  return isFirstTimeUser() && !GlobalLaunchState.shortLivedBssoOpenToken ? (
    <Navigate to={LANDING_PATH} />
  ) : (
    <LoginComponent />
  );
}

function GenericErrorPage() {
  return (
    <PageContainer>
      <EmptyState
        title='Something Went Wrong'
        variant='error'
        size='large'
        description='An unexpected error has occurred. Please try again.'
        actions={[
          {
            onClick: () => window.location.reload(),
            children: 'Join Again',
            kind: 'primary',
          },
        ]}
      />
    </PageContainer>
  );
}

function LandingErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message === ERROR_TYPE.NOT_AUTHENTICATED) {
    return <LandingPage />;
  }

  return <GenericErrorPage />;
}

function JoinRoomErrorBoundary() {
  const error = useRouteError() as Error;
  const notification = useNotification();

  if (error.message === ERROR_TYPE.NOT_AUTHENTICATED) {
    return <LoginComponent />;
  }
  if (error.message === ERROR_TYPE.BAD_JWT) {
    return <AuthenticationErrorPage />;
  }
  if (error.message === ERROR_TYPE.UNSUPPORTED_BROWSER) {
    return (
      <PageContainer>
        <EmptyState
          title='Unsupported Browser'
          variant='error'
          size='large'
          description='The Firefox browser is not supported. Chrome is recommended for the best experience.'
          actions={[
            {
              onClick: () => {
                copyToClipboard(sanitizeUrl(window.location.href));
                notification.addInfo({
                  message: 'Copied meeting web link.',
                });
              },
              children: 'Copy Web Link',
              kind: 'primary',
            },
          ]}
        />
      </PageContainer>
    );
  }

  if (
    error.message === ERROR_TYPE.NOT_AUTHORIZED_FOR_MEETING ||
    error.message === ERROR_TYPE.ROOM_NOT_FOUND
  ) {
    return (
      <InfoPage
        variant='gated'
        title='Unable to Join This Meeting'
        description='You do not have access to this meeting.'
        linkText='Return to Home Screen'
      />
    );
  }

  logger.error(`Uncaught error while joining room: ${error.message}`);

  return <GenericErrorPage />;
}

const router = createBrowserRouter([
  {
    path: ROOT_PATH,
    loader: userDataLoader,
    element: <Navigate to={HOME_PATH} />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: LANDING_PATH,
    loader: userDataLandingFallbackLoader,
    element: <Navigate to={HOME_PATH} />,
    errorElement: <LandingErrorBoundary />,
  },
  {
    path: LOGOUT_PATH,
    loader: userDataLoader,
    element: <Navigate to={HOME_PATH} />,
    errorElement: <LogoutPage />,
  },
  {
    path: ERROR_PATH,
    loader: userDataLoader,
    element: <Navigate to={HOME_PATH} />,
    errorElement: <AuthenticationErrorPage />,
  },
  {
    path: HOME_PATH,
    loader: homeDataLoader,
    element: <Home />,
    errorElement: <LoginComponent />,
    id: 'home-router',
  },
  {
    path: MEETING_PATH,
    loader: joinRoomDataLoader,
    element: <Room />,
    errorElement: <JoinRoomErrorBoundary />,
    id: 'rooms-router',
  },
  {
    path: HELP_PATH,
    loader: userDataLoader,
    element: <Help />,
    errorElement: <LoginComponent />,
  },
  {
    path: TEST_WEBRTC_PATH,
    loader: userDataLoader,
    element: <TestWebRTC />,
    errorElement: <LoginComponent />,
  },
  {
    path: '/*',
    element: <Navigate to={ROOT_PATH} />,
  },
]);

export default router;
